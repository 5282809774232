import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Confetti from 'react-canvas-confetti'

function SpinnerConfetti({ triggerState })
{
    const initRef = useRef()
    const [fire, setFire] = useState()

    function explodeEverything()
    {
        setFire({})
    }

    useEffect(() =>
    {
        if (!initRef.current)
        {
            initRef.current = true
            return
        }

        if (triggerState)
        {
            const intervalRef = setInterval(explodeEverything, 40)
            setTimeout(() =>
            {
                clearInterval(intervalRef)
            }, 3000)
        }
    }, [triggerState])

    return (
        <>
            <Confetti
                fire={fire}
                particleCount={8}
                angle={60}
                spread={55}
                startVelocity={55}
                origin={{ x: 0, y: 0.75 }}
                className={`hidden md:block fixed md:inset-0 w-full h-full pointer-events-none z-40`}
            />

            <Confetti
                fire={fire}
                particleCount={8}
                angle={120}
                spread={55}
                startVelocity={75}
                origin={{ x: 1, y: 0.75 }}
                className={`hidden md:block fixed md:inset-0 w-full h-full pointer-events-none z-50`}
            />

            <Confetti
                fire={fire}
                particleCount={8}
                angle={90}
                spread={55}
                startVelocity={55}
                origin={{ x: 0.5, y: 1 }}
                className={`fixed inset-0 w-full h-full pointer-events-none z-60`}
            />
        </>
    )
}

SpinnerConfetti.propTypes = {
    triggerState: PropTypes.any
}

export default SpinnerConfetti