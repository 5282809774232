import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import beepSfx from '../../audio/beep.wav'
import clapSfx from '../../audio/clap.mp3'
import firecrackerSfx from '../../audio/firecracker.wav'

function SpinnerAudio({ startSpinning })
{
    const initRef = useRef(false)
    const beepSfxRef = useRef()
    const clapSfxRef = useRef()
    const firecrackerSfxRef = useRef()

    useEffect(() =>
    {
        if (!initRef.current)
        {
            initRef.current = true
            return
        }

        if (startSpinning)
        {
            beepSfxRef.current.loop = true
            beepSfxRef.current.play()
        }
        else
        {
            beepSfxRef.current.pause()
            firecrackerSfxRef.current.play()
            clapSfxRef.current.play()
        }
    }, [startSpinning])

    return (
        <section>
            <audio ref={beepSfxRef}>
                <source src={beepSfx} type="audio/wav" />
            </audio>

            <audio ref={clapSfxRef}>
                <source src={clapSfx} type="audio/mp3" />
            </audio>

            <audio ref={firecrackerSfxRef}>
                <source src={firecrackerSfx} type="audio/wav" />
            </audio>
        </section>
    )
}

SpinnerAudio.defaultProps = {
    startSpinning: false,
    onInit: () => { },
}

SpinnerAudio.propTypes = {
    startSpinning: PropTypes.bool,
    onInit: PropTypes.func,
}

export default SpinnerAudio