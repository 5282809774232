import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function SpinnerWheel({ names, startSpinning })
{
    const counter = useRef(0)
    const isSpinningRef = useRef(false)
    const intervalRef = useRef(null)

    const [potentialWinners, setPotentialWinners] = useState([])
    const [wheelNames, setWheelNames] = useState([])

    useEffect(() =>
    {
        spin()
    }, [potentialWinners])

    useEffect(() =>
    {
        assignNamesOnWheel()
    }, [names])

    useEffect(() =>
    {
        if (startSpinning)
        {
            intervalRef.current = setInterval(spin, 50)
            isSpinningRef.current = true
        }
        else 
        {
            if (intervalRef.current !== null)
            {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
            isSpinningRef.current = false
        }
    }, [startSpinning])

    function spin()
    {
        const numItems = potentialWinners.length

        const updates = []
        for (let i = 0; i < 9; i++)
        {
            let newI = i + counter.current
            if (newI >= numItems)
            {
                newI = newI - numItems
            }
            updates.push(potentialWinners[newI])
        }
        setWheelNames(updates)

        counter.current += 1
        if (counter.current >= numItems)
        {
            counter.current = 0
        }
    }

    function assignNamesOnWheel()
    {
        // remove the names that are empty strings or empty spaces
        const filteredNames = names.filter(x => x.trim())
        let list = filteredNames

        const numNamesToBeOnWheel = filteredNames.length
        if (numNamesToBeOnWheel === 0)
        {
            for (let i = 0; i < 9; i++)
            {
                list.push("Nobody")
            }
        }
        else if (numNamesToBeOnWheel < 9)
        {
            // so Dave becomes Dave, Dave, Dave, Dave ....
            // so Dave, Omana becomes Dave, Omana, Dave, Omana, Dave, ...
            for (let i = numNamesToBeOnWheel; i < 9; i++)
            {
                list.push(filteredNames[i % numNamesToBeOnWheel])
            }
        }

        setPotentialWinners(list)
    }

    // const numWheels = colorWheel.length
    function getColor(idx)
    {
        const colors = [
            ['#F87171', '#EF4444', '#DC2626'], // 'text-red-500',
            ['#FBBF24', '#F59E0B', '#D97706'], // 'text-yellow-500',
            ['#34D399', '#10B981', '#059669'], // 'text-green-500',
            ['#60A5FA', '#3B82F6', '#2563EB'], // 'text-blue-500',
            ['#818CF8', '#6366F1', '#4F46E5'], // 'text-indigo-500',
            ['#A78BFA', '#8B5CF6', '#7C3AED'], // 'text-purple-500',
            ['#F472B6', '#EC4899', '#DB2777'], // 'text-pink-500',
            ['#9CA3AF', '#6B7280', '#4B5563'], // 'text-gray-500',
        ]
        const curr = (counter.current + idx) % colors.length

        return colors[curr]
    }

    function getRotation(idx)
    {
        switch (idx)
        {
            case 8: return 'rotateX(80deg)'
            case 7: return 'rotateX(65deg)'
            case 6: return 'rotateX(55deg)'
            case 5: return 'rotateX(45deg)'
            case 4: return 'rotateX(0deg)'
            case 3: return 'rotateX(-45deg)'
            case 2: return 'rotateX(-55deg)'
            case 1: return 'rotateX(-65deg)'
            default: return 'rotateX(-75deg)'
        }
    }

    function getPolygon(idx)
    {
        switch (idx)
        {
            case 8: return {
                viewBox: '0 0 100 5',
                points: '5,0 95,0 97,5 3,5',
            }
            case 7: return {
                viewBox: '0 0 100 5',
                points: '3,0 97,0 98,5 2,5',
            }
            case 6: return {
                viewBox: '0 0 100 7',
                points: '2,0 98,0 99,7 1,7',
            }
            case 5: return {
                viewBox: '0 0 100 9',
                points: '1,0 99,0 99.5,9 0.5,9',
            }
            case 4: return {
                viewBox: '0 0 100 15',
                points: '0.5,0 99.5,0 99.5,15 0.5,15',
            }
            case 3: return {
                viewBox: '0 0 100 9',
                points: '0.5,0 99.5,0 99,9 1,9',
            }
            case 2: return {
                viewBox: '0 0 100 7',
                points: '1,0 99,0 98,7 2,7',
            }
            case 1: return {
                viewBox: '0 0 100 5',
                points: '2,0 98,0 97,5 3,5',
            }
            default: return {
                viewBox: '0 0 100 5',
                points: '3,0 97,0 95,5 5,5',
            }
        }
    }

    function getTextSize(idx)
    {
        switch (idx)
        {
            case 8:
            case 0:
                return 'text-lg text-opacity-40'
            case 7:
            case 1:
                return 'text-xl text-opacity-60'
            case 6:
            case 2:
                return 'text-2xl text-opacity-80'
            case 5:
            case 3:
                return 'text-3xl'
            default: return 'text-4xl'
        }
    }

    return (
        <section className="w-full h-full">
            <div className="flex flex-col items-center justify-center w-full h-full text-white text-center font-medium">
                {
                    [8, 7, 6, 5, 4, 3, 2, 1, 0].map(idx =>
                    {
                        // I need to reverse it to make the wheel turn downward
                        const polygon = getPolygon(idx)
                        const colors = getColor(idx)
                        return (
                            <div key={idx} className="relative w-full">
                                <div className={`absolute z-10 truncate flex items-center justify-center w-full h-full text-white ${getTextSize(idx)}`} style={{ transform: getRotation(idx) }}>{wheelNames[idx]}</div>
                                <svg viewBox={polygon.viewBox}>
                                    <defs>
                                        <linearGradient id={`grad${idx}`} x1="0%" y1="0%" x2="100%" y2="100%">
                                            <stop offset="0%" style={{ stopColor: colors[0], stopOpacity: '1' }} />
                                            <stop offset="50%" style={{ stopColor: colors[1], stopOpacity: '1' }} />
                                            <stop offset="100%" style={{ stopColor: colors[2], stopOpacity: '1' }} />
                                        </linearGradient>
                                    </defs>
                                    <polygon points={polygon.points} fill={`url(#grad${idx})`} stroke="white" strokeWidth="0.5" strokeOpacity="0.8" />
                                </svg>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

SpinnerWheel.defaultProps = {
    names: [],
    startSpinning: false,
}

SpinnerWheel.propTypes = {
    names: PropTypes.array.isRequired,
    startSpinning: PropTypes.bool,
}

export default SpinnerWheel
