import React from "react"
import HomepageMain from "../components/homepage/homepage-main"

import Layout from "../components/layout"
import SEO from "../components/seo"

function IndexPage()
{
  return (
    <Layout>
      <SEO />

      <HomepageMain />
    </Layout>
  )
}

export default IndexPage
