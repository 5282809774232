import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

function SpinnerTextArea({ names, setNames, disable })
{
    const namesRef = useRef()
    const numbersRef = useRef()

    useEffect(() =>
    {
        namesRef.current.value = names.join('\n')
        updateLineNumbers(names.length)
    }, [])

    function updateLineNumbers(numLines)
    {
        let inputForNumbersRef = ""
        for (let i = 1; i <= numLines; i++)
        {
            inputForNumbersRef += `${i}${i !== numLines ? '\n' : ''}`
        }
        numbersRef.current.value = inputForNumbersRef

        numbersRef.current.style.height = `${namesRef.current.scrollHeight}px`
        namesRef.current.style.height = `${namesRef.current.scrollHeight}px`
    }

    function handleChange()
    {
        const newVal = namesRef.current.value.split('\n')
        setNames(newVal)

        updateLineNumbers(newVal.length)
    }

    function handleScroll(e)
    {
        numbersRef.current.scrollTop = e.target.scrollTop
    }

    return (
        <section className="w-full h-full">
            <div className="flex flex-row items-start justify-start w-full h-full overflow-y-scroll bg-indigo-50 rounded-xl font-mono">

                <textarea
                    readOnly
                    // disabled << can't put this because it disables styling on Safari
                    ref={numbersRef}
                    rows={20}
                    nowrap="nowrap"
                    wrap="off"
                    className="bg-indigo-400 w-16 py-0 my-0 text-white text-right text-lg focus:ring-transparent focus:border-transparent border-transparent resize-none overflow-x-scroll overflow-y-hidden"
                    style={{ lineHeight: '2rem' }}
                />

                <textarea
                    ref={namesRef}
                    disabled={disable}
                    rows={20}
                    nowrap="nowrap"
                    wrap="off"
                    onChange={handleChange}
                    onScroll={handleScroll}
                    autoCapitalize="false"
                    autoComplete="false"
                    autoCorrect="false"
                    spellCheck="false"
                    className="bg-indigo-50 text-indigo-700 w-full py-0 my-0 text-lg focus:ring-transparent focus:border-transparent border-transparent resize-none overflow-x-scroll overflow-y-hidden"
                    style={{ backgroundImage: 'linear-gradient(#F1F1F1 50%, #F9F9F9 50%)', backgroundSize: '100% 4rem', lineHeight: '2rem' }}
                />
            </div>
        </section>
    )
}

SpinnerTextArea.defaultProps = {
    names: [],
    setNames: () => { },
    disable: false,
}

SpinnerTextArea.propTypes = {
    names: PropTypes.array,
    setNames: PropTypes.func,
    disable: PropTypes.bool,
}

export default SpinnerTextArea