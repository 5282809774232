import React, { useRef, useState } from 'react'
import { defaultNames } from '../../helpers/config'
import SpinnerAudio from './spinner-audio'
import SpinnerConfetti from './spinner-confetti'
// import SpinnerControls from './spinner-controls'
import SpinnerTextArea from './spinner-textarea'
import SpinnerWheel from './spinner-wheel'

function SpinnerMainLightMode()
{
    const [names, setNames] = useState(defaultNames)

    const startSpinningRef = useRef()
    const [startSpinning, setStartSpinning] = useState(false)

    function toggleSpin()
    {
        startSpinningRef.current = !startSpinningRef.current
        setStartSpinning(startSpinningRef.current)
    }

    return (
        <section>
            <div className="grid grid-cols-1 sm:grid-cols-2 grid-rows-8 sm:grid-rows-5 w-full h-screen bg-gradient-to-br from-indigo-500 via-pink-500 to-red-500 text-white overflow-y-scroll">

                <div className="sm:col-span-2 row-span-1 order-1 flex flex-col items-center justify-center py-4 shadow-xl">
                    <div
                        contentEditable
                        suppressContentEditableWarning
                        autoCapitalize="false"
                        autoComplete="false"
                        autoCorrect="false"
                        spellCheck="false"
                        className="font-extrabold text-6xl text-center px-3 w-full focus:outline-none outline-none" >LuckyDraw</div>

                    <div
                        contentEditable
                        suppressContentEditableWarning
                        autoCapitalize="false"
                        autoComplete="false"
                        autoCorrect="false"
                        spellCheck="false"
                        className="font-light text-xl text-center px-3 w-full focus:outline-none outline-none" >Edit this text directly by clicking here.</div>
                </div>

                <div className="col-span-2 sm:col-span-1 row-span-3 sm:row-span-3 order-4 sm:order-2 flex items-center justify-center p-4">
                    <div className="w-full h-full max-w-screen-sm">
                        <SpinnerTextArea names={names} setNames={setNames} />
                    </div>
                </div>

                <div className="col-span-2 sm:col-span-1 row-span-3 sm:row-span-3 order-2 sm:order-3 flex items-center justify-center p-4">
                    <div className="w-full h-full max-w-screen-sm">
                        <SpinnerWheel names={names} startSpinning={startSpinning} />
                    </div>
                </div>

                <div className="sm:col-span-2 row-span-1 order-3 sm:order-4 flex flex-col items-center justify-center space-y-4 p-4">
                    <button onClick={toggleSpin} className={`font-bold text-4xl bg-gradient-to-br ${startSpinning ? 'from-yellow-400 to-red-500' : 'from-green-400 to-blue-500'} px-10 py-4 border-4 border-white border-opacity-80 rounded-2xl shadow-xl focus:outline-none cursor-pointer`}>
                        {startSpinning ? 'STOP' : 'SPIN'}</button>
                    {/* <div className="hidden lg:flex italic">*Hint: Spacebar or Enter to Spin or Stop.</div> */}
                </div>

            </div>

            <SpinnerAudio startSpinning={startSpinning} />

            {/* <SpinnerControls
                onSpacebar={toggleSpin}
                onEnter={toggleSpin}
            /> */}

            {/* Confetti - has to be last in DOM */}
            <div className="fixed w-screen h-screen inset-0 z-50 pointer-events-none">
                <SpinnerConfetti triggerState={!startSpinning} />
            </div>

        </section>
    )
}

export default SpinnerMainLightMode