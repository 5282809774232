import React from 'react'
import SpinnerMainLightMode from '../spinner/spinner-main-light-mode'

function HomepageMain()
{
    return (
        <section>
            <SpinnerMainLightMode />
        </section>
    )
}

export default HomepageMain